var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - List Update Requests" }
      }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Update requests")
                  ]),
                  _c(
                    "gov-grid-row",
                    [
                      _c(
                        "gov-grid-column",
                        { attrs: { width: "two-thirds" } },
                        [
                          _c(
                            "ck-table-filters",
                            { on: { search: _vm.onSearch } },
                            [
                              _c(
                                "gov-form-group",
                                [
                                  _c(
                                    "gov-label",
                                    { attrs: { for: "filter[entry]" } },
                                    [_vm._v("Entry")]
                                  ),
                                  _c("gov-input", {
                                    attrs: {
                                      id: "filter[entry]",
                                      name: "filter[entry]",
                                      type: "search"
                                    },
                                    model: {
                                      value: _vm.filters.entry,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "entry", $$v)
                                      },
                                      expression: "filters.entry"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "extra-filters" },
                                [
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        {
                                          attrs: {
                                            for: "filter[updateable_type]"
                                          }
                                        },
                                        [_vm._v("Type")]
                                      ),
                                      _c("gov-select", {
                                        attrs: {
                                          id: "filter[updateable_type]",
                                          name: "filter[updateable_type]",
                                          options: _vm.updateableTypes
                                        },
                                        model: {
                                          value: _vm.filters.updateableType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "updateableType",
                                              $$v
                                            )
                                          },
                                          expression: "filters.updateableType"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("ck-resource-listing-table", {
                    ref: "updateRequestsTable",
                    attrs: {
                      uri: "/update-requests",
                      params: _vm.params,
                      "default-sort": "-created_at",
                      columns: [
                        {
                          heading: "User",
                          render: function(updateRequest) {
                            return updateRequest.user
                              ? updateRequest.user.first_name +
                                  " " +
                                  updateRequest.user.last_name
                              : "N/A"
                          }
                        },
                        {
                          heading: "Type",
                          render: function(updateRequest) {
                            return _vm.displayType(
                              updateRequest.updateable_type
                            )
                          }
                        },
                        {
                          heading: "Entry",
                          sort: "entry",
                          render: function(updateRequest) {
                            return updateRequest.entry
                          }
                        },
                        {
                          heading: "Date / Time",
                          sort: "created_at",
                          render: function(updateRequest) {
                            return _vm.formatDateTime(updateRequest.created_at)
                          }
                        }
                      ],
                      "view-route": function(updateRequest) {
                        return {
                          name: "update-requests-show",
                          params: { updateRequest: updateRequest.id }
                        }
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }